import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

import { List, Spin } from "antd";

import "./Network.css"; // Import the CSS file for styling

const Network = () => {
  const { id } = useParams();
  const [orderDetails, setOrderDetails] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);

    axios
      .get(`https://ecommerce-api.webappdevs.com/api/users/${id}`)
      .then((response) => {
        setOrderDetails(response.data);
        setLoading(false);
        // console.log("Order details fetched successfully:", response.data);
      })
      .catch((error) => {
        console.error("Error fetching order details:", error);
        setLoading(false);
      });
  }, [id]);

  if (loading) {
    return <Spin size="large" />;
  }

  if (!orderDetails) {
    return <p>Order details not found.</p>;
  }

  return (
    <div>
      <div className="order-details-container">
        <h1 className="NewUserHeading">Network for user</h1>
        <div className="order-details-content">
          <p>
            <span className="detail-label">ID:</span> {orderDetails.id}
            <br />
            <span className="detail-label">Name:</span> {orderDetails.name}
            <br />
            <span className="detail-label">number:</span> {orderDetails.number}
            <br />
            <span className="detail-label">address:</span>
            {orderDetails.address}
            <br />
            <span className="detail-label">Referential Code:</span>
            {orderDetails.referential_code}
          </p>
        </div>
        <List>
          <hr className="divider" />
          {orderDetails.referrals.map((product, index) => (
            <List.Item key={index} className="product-item">
              <div className="product-info">
                <h1 className="NewUserHeading">New user</h1>
                <p>ID: {product.id}</p>
                <p>Name: {product.name}</p>
                <p>Email: {product.email}</p>
                <p>Number: {product.number}</p>
                <p>Address: {product.address}</p>
                <p>Referential Code: {product.referential_code}</p>
              </div>
            </List.Item>
          ))}
        </List>
      </div>
    </div>
  );
};

export default Network;
