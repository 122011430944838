import { Button, Form, Input, message } from "antd";
import { SaveFilled, HomeFilled } from "@ant-design/icons";
import { Row, Col } from "antd";
import { useParams } from "react-router-dom";
import "./UpdateSlider.css";
import { useEffect } from "react";
import axios from "axios";
import TextArea from "antd/es/input/TextArea";

// import { IconButton } from "@mui/material";
const UpdateProducts = ({ storeProducts }) => {
  // eslint-disable-next-line no-unused-vars
  const { id } = useParams();
  const product = Array.isArray(storeProducts)
    ? storeProducts.find((item) => item.id === parseInt(id))
    : null;

  const [form] = Form.useForm();
  //todo this is get API for User
  useEffect(() => {
    if (product) {
      const nameObj = JSON.parse(product.name);
      const descriptionObj = JSON.parse(product.description);
      form.setFieldsValue({
        TitleKurdish: nameObj.ku || "", // Access nameObj.ku directly
        TitleArabic: nameObj.ar || "", // Access nameObj.ar directly
        Price: product.price || "",
        Discount: product.discounted_price || "",
        points: product.points || "",
        DescriptionKurdish: descriptionObj.ku || "", // Access descriptionObj.ku directly
        DescriptionArabic: descriptionObj.ar || "", // Access descriptionObj.ar directly
      });
    }
  }, [product, form]);

  //todo update user
  const onFinish = async (values) => {
    try {
      const updatedValues = {
        ...values,
        points: values.points,
        discounted_price: values.Discount, // Rename 'Discount' to 'discounted_price'
        price: values.Price, // Use 'Price' as 'price'
        name: JSON.stringify({
          ku: values.TitleKurdish,
          ar: values.TitleArabic,
        }),
        description: JSON.stringify({
          ku: values.DescriptionKurdish,
          ar: values.DescriptionArabic,
        }),
      };

      const response = await axios.put(
        `https://ecommerce-api.webappdevs.com/api/products/${id}`,
        updatedValues
      );

      if (response.status === 200) {
        message.success("Successfully updated");
        // Optionally refetch data or update local state
        // Example: fetchData(); // Assuming fetchData is a function to refresh data
      }
    } catch (error) {
      message.error("Failed to update");
    }
  };

  const onFinishFailed = () => {
    // console.log("Failed:", errorInfo);
    message.error("Error");
  };

  return (
    <div>
      {/* <div className="login-container"></div> */}
      <div className="login-form-container">
        <Form
          // validateMessages={validateMessages}
          form={form}
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 24 }}
          className="login-form"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <div className="login-form-title"> Update Information</div>

          {/* //todo this is User Name */}
          <Form.Item name="TitleKurdish" label="Title Kurdish">
            <Input placeholder="Type your Title Kurdish" />
          </Form.Item>

          <Form.Item name="TitleArabic" label="Title Arabic">
            <Input placeholder="Type your Title Arabic" />
          </Form.Item>

          <Form.Item name="Price" label="Price">
            <Input placeholder="Type your Price" />
          </Form.Item>
          <Form.Item name="Discount" label="Discount">
            <Input placeholder="Type your discount" />
          </Form.Item>
          <Form.Item name="points" label="points">
            <Input placeholder="Type your points" />
          </Form.Item>
          <Form.Item name="DescriptionKurdish" label="Description Kurdish">
            <TextArea rows={3} name="DescriptionKurdish" />
          </Form.Item>
          <Form.Item name="DescriptionArabic" label="Description Arabic">
            <TextArea rows={3} name="DescriptionArabic" />
          </Form.Item>
          <Form.Item
            wrapperCol={{
              offset: 6,
              span: 12,
              xs: { span: 24 },
              sm: { span: 12, offset: 6 },
            }}
            style={{ textAlign: "center" }}
          >
            <Row gutter={16}>
              <Col xs={24} sm={12}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{
                    width: "100%",
                    backgroundColor: "#f44336",
                    borderColor: "#f44336",
                  }}
                  icon={<SaveFilled />}
                >
                  Update
                </Button>
              </Col>
              <Col xs={24} sm={12}>
                <Button
                  type="primary"
                  href="/"
                  style={{
                    width: "100%",
                    backgroundColor: "#2196f3",
                    borderColor: "#2196f3",
                  }}
                >
                  <HomeFilled />
                  Dashboard
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default UpdateProducts;
