import { Table, Input } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
// import OrderDetails from "./Order";
const OrderTable = () => {
  //todo -----------------Search-----------
  const [SearchText, setSearchText] = useState("");
  //todo----------------------------------------------
  const [dataSource, setDataSource] = useState([]);
  useEffect(() => {
    GetApiOrder();
  }, []);
  const GetApiOrder = async () => {
    const res = await axios.get(
      "https://ecommerce-api.webappdevs.com/api/orders"
    );

    setDataSource(
      res.data.map((item) => {
        return {
          id: item.id,
          username: item.username,
          phone_number: item.phone_number,
          total_amount: item.total_amount,
          address: item.address,
          status_name: item.status_name,
          status: item.status,
        };
      })
    );
  };

  const columns = [
    {
      title: "id",
      dataIndex: "id",
      key: "id",

      filteredValue: [SearchText],
      onFilter: (value, record) => {
        return (
          String(record.username).toLowerCase().includes(value.toLowerCase()) ||
          String(record.phone_number)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.id).toLowerCase().includes(value.toLowerCase())
        );
      },
    },
    {
      title: "username",
      dataIndex: "username",
      key: "username",
      render: (text, record) => (
        <Link to={`/OrderTable/OrderDetails/${record.id}`}>{text}</Link>
      ),
    },
    {
      title: "total_amount",
      dataIndex: "total_amount",
      key: "total_amount ",
      render: (text, record) => (
        <Link to={`/OrderTable/OrderDetails/${record.id}`}>{text}</Link>
      ),
    },
    {
      title: "phone_number",
      dataIndex: "phone_number",
      key: "phone_number",
      render: (text, record) => (
        <Link to={`/OrderTable/OrderDetails/${record.id}`}>{text}</Link>
      ),
    },
    {
      title: "address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "status_name",
      dataIndex: "status_name",
      key: "status_name",
      render: (text, record) => {
        let style = {}; // Initialize an empty style object
        switch (record.status_name) {
          case "received":
            style = {
              background: "blue",
              color: "white",
              fontSize: 14,
              fontWeight: "bold",
              padding: 5,
            };
            break;
          case "in delivery":
            style = {
              background: "yellow",
              color: "black",
              fontSize: 14,
              fontWeight: "bold",
              padding: 5,
            };
            break;
          case "completed":
            style = {
              background: "green",
              color: "white",
              fontSize: 14,
              fontWeight: "bold",
              padding: 5,
            };
            break;
          case "cancelled":
            style = {
              background: "red",
              color: "white",
              fontSize: 14,
              fontWeight: "bold",
              padding: 5,
            };
            break;
          default:
            break;
        }
        return <span style={style}>{record.status_name}</span>;
      },
    },
  ];
  return (
    <div>
      <Input.Search
        placeholder="Search here ...."
        style={{
          marginBottom: 15,
          marginTop: 12,
          width: 300,
        }}
        onSearch={(value) => {
          setSearchText(value);
        }}
        onChange={(e) => {
          setSearchText(e.target.value);
        }}
      />
      <Table
        dataSource={dataSource.map((item) => ({ ...item, key: item.id }))}
        columns={columns}
      />
    </div>
  );
};

export default OrderTable;
