import "./Admin.css";
import React, { useState } from "react";
import { Button, Form, Input, message } from "antd";
import { UserOutlined, LockOutlined, LoginOutlined } from "@ant-design/icons";

const Admin = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = () => {
    if (username.trim() === "admin" && password.trim() === "12345") {
      const sessionToken = "example_session_token";
      localStorage.setItem("sessionToken", sessionToken);
      window.location.href = "/";
    } else {
      alert("Invalid credentials. Please try again :(");
    }
  };

  const onFinish = (values) => {
    // console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
    message.error("Error");
  };

  return (
    <div className="login-container">
      <div className="login-form-container">
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          className="login-form"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <div className="login-form-title">Sign in Continue</div>
          <Form.Item
            name="username"
            label={<span className="form-label">Username</span>}
            rules={[
              {
                type: "text",
                required: true,
                message: "Email is invalid",
              },
            ]}
          >
            <Input
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              prefix={<UserOutlined />}
            />
          </Form.Item>

          <Form.Item
            name="password"
            label={<span className="form-label">Password</span>}
            rules={[
              {
                required: true,
                message: "Password is invalid",
              },
            ]}
          >
            <Input.Password
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              prefix={<LockOutlined />}
            />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 6, span: 12 }}>
            <Button
              type="primary"
              htmlType="submit"
              icon={<LoginOutlined />}
              onClick={handleLogin}
            >
              Login
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Admin;
