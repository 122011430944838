import { EditOutlined } from "@mui/icons-material";
import { Table, Button, message, Modal, Input } from "antd";
// import axios from"axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import { UploadOutlined } from "@ant-design/icons";
const Products = () => {
  //todo -----------------Search-----------
  const [SearchText, setSearchText] = useState("");
  //todo----------------------------------------------
  const [dataSource, setDataSource] = useState([]);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  useEffect(() => {
    getApiProducts();
  }, []);

  const getApiProducts = async () => {
    try {
      const res = await fetch(
        "https://ecommerce-api.webappdevs.com/api/products"
      );
      if (!res.ok) {
        throw new Error(`HTTP error! Status: ${res.status}`);
      }
      const data = await res.json();
      setDataSource(
        data.data.map((item) => {
          const nameObj = JSON.parse(item.name);
          const descriptionObj = JSON.parse(item.description);

          return {
            id: item.id,
            nameKurdish: nameObj.ku || "N/A",
            nameArabic: nameObj.ar || "N/A",
            Price: item.price,
            image_url: item.image_url
              ? `https://ecommerce-api.webappdevs.com/storage/${item.image_url}`
              : null,
            discounted_price: item.discounted_price,
            detailedKU: descriptionObj.ku || "N/A",
            detailedAr: descriptionObj.ar || "N/A",
            points: item.points,
            P_S: item.pinned,
          };
        })
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  //todo remove item
  const handleDelete = (id) => {
    setDeleteItemId(id);
    setIsDeleteModalVisible(true);
  };
  const handleConfirmedDelete = async () => {
    try {
      console.log("Deleting product with ID:", deleteItemId);
      await fetch(
        `https://ecommerce-api.webappdevs.com/api/products/${deleteItemId}`,
        {
          method: "DELETE",
        }
      );

      message.success("Product deleted successfully");
      console.log("Product deleted successfully");

      // Log the result of getApiProducts
      const updatedData = await getApiProducts();
      console.log("Updated Data:", updatedData);

      // Reset deleteItemId and hide the modal
      setDeleteItemId(null);
      setIsDeleteModalVisible(false);
    } catch (error) {
      console.error("Error deleting product:", error);
      message.error("Error deleting product");
    }
  };
  //todo update

  const columns = [
    {
      title: "id",
      dataIndex: "id",
      key: "id",
      filteredValue: [SearchText],
      onFilter: (value, record) => {
        return (
          String(record.nameKurdish)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.nameArabic)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.id).toLowerCase().includes(value.toLowerCase())
        );
      },
    },
    {
      title: "Name Ku",
      dataIndex: "nameKurdish",
      key: "nameKurdish",
    },
    {
      title: "Name Ar",
      dataIndex: "nameArabic",
      key: "nameArabic",
    },
    {
      title: "image_url",
      dataIndex: "image_url",
      key: "image_url",
      render: (item) => {
        // console.log("Image URL:", item);
        if (item) {
          return (
            <img
              src={item}
              alt="img"
              style={{ width: "50px", height: "50px", objectFit: "cover" }}
            />
          );
        } else {
          return "N/A";
        }
      },
    },
    {
      title: "Price",
      dataIndex: "Price",
      key: "price",
    },
    {
      title: "discounted_price",
      dataIndex: "discounted_price",
      key: "discounted_price",
    },
    {
      title: "description KU",
      dataIndex: "detailedKU",
      key: "detailedKU",
    },
    {
      title: "description Ar",
      dataIndex: "detailedAr",
      key: "detailedAr",
    },
    {
      title: "products",
      dataIndex: "P_S",
      key: "pinned",
    },
    {
      title: "points",
      dataIndex: "points",
      key: "points",
    },

    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div>
          {/* <Button type="primary" onClick={() => handleUpdate(record)}>
            Update
          </Button> */}

          <span> </span>
          <Button
            type="danger"
            style={{ background: "red", border: "red", color: "white" }}
            onClick={() => handleDelete(record.id)}
          >
            Delete
          </Button>
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "Action",
      key: "action",
      render: (text, record) => (
        <Link to={`/Products/UpdateProducts/${record.id}`}>
          {text}
          <Button type="primary" icon={<EditOutlined />}>
            Edit
          </Button>
        </Link>
      ),
    },
  ];

  return (
    <div>
      <Input.Search
        placeholder="Search here ...."
        style={{
          marginBottom: 15,
          marginTop: 12,
          width: 300,
        }}
        onSearch={(value) => {
          setSearchText(value);
        }}
        onChange={(e) => {
          setSearchText(e.target.value);
        }}
      />
      <Table
        dataSource={dataSource.map((item) => ({ ...item, key: item.id }))}
        columns={columns}
      />
      {/* Delete Modal */}
      <Modal
        title="Confirm Deletion"
        open={isDeleteModalVisible}
        onOk={handleConfirmedDelete}
        onCancel={() => setIsDeleteModalVisible(false)}
      >
        <p>Are you sure you want to delete this product?</p>
      </Modal>
      {/* Update Modal */}
      {/*
      <Modal
        title="Update Product"
        open={visible} 
        onOk={handleUpdateSubmit}
        onCancel={() => setVisible(false)}
      >
        <Form form={form} layout="vertical">
          <Form.Item name="nameKurdish" label="Name Kurdish">
            <Input />
          </Form.Item>
          <Form.Item name="nameArabic" label="Name Arabic">
            <Input />
          </Form.Item>
          <Form.Item name="image_url" label="Image URL">
            <Upload
              maxCount={1}
              beforeUpload={() => false}
              fileList={form.getFieldValue("image_url") || []}
              onChange={(info) => {
                form.setFieldsValue({ image_url: info.fileList });
              }}
            >
              <Button icon={<UploadOutlined />}>Upload Image</Button>
            </Upload>
          </Form.Item>
          <Form.Item name="Price" label="Price">
            <Input type="number" />
          </Form.Item>
          <Form.Item name="discounted_price" label="Discounted Price">
            <Input type="number" />
          </Form.Item>
          <Form.Item name="detailedKU" label="Description Kurdish">
            <Input.TextArea />
          </Form.Item>
          <Form.Item name="detailedAr" label="Description Arabic">
            <Input.TextArea />
          </Form.Item>
          <Form.Item name="P_S" label="Pinned">
            <Input type="number" />
          </Form.Item>
        </Form>
      </Modal>*/}
    </div>
  );
};

export default Products;
