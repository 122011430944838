import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
// import NavBar from "./FrontEnd/Navbar/Navbar";
import Profile from "./FrontEnd/pages/Profile";
import AddItem from "./FrontEnd/pages/AddItem";

import Dashboard from "./FrontEnd/pages/dashboard";
import OrderTable from "./FrontEnd/pages/OrderTable";
import OrderDetails from "./FrontEnd/pages/Order";
import Network from "./FrontEnd/pages/Network";
import Slider from "./FrontEnd/pages/Slider";
import Products from "./FrontEnd/pages/ProductAdd";
import NotFound from "./FrontEnd/pages/NotFound";
import NavberUpdate from "./FrontEnd/Navbar/NavberUpdate";
import UpdateSlider from "./FrontEnd/pages/UpdateSlicder";
import axios from "axios";
import UpdateProducts from "./FrontEnd/pages/UpdateProducts";

const Home = ({ dataSource }) => {
  const [storeSlider, setStoreSlider] = useState({});
  const [storeProducts, setStoreProducts] = useState({});
  useEffect(() => {
    axios
      .get(`https://ecommerce-api.webappdevs.com/api/products/pinned`)
      .then((response) => {
        // Ensure correct access to response data
        setStoreSlider(response.data.data);
        console.log("response.data.data", response.data.data);
      })

      .catch((error) => {
        console.error("Error fetching order details:", error);
      });
  }, []);
  useEffect(() => {
    axios
      .get(`https://ecommerce-api.webappdevs.com/api/products`)
      .then((response) => {
        // Ensure correct access to response data
        setStoreProducts(response.data.data);
        console.log("response.data.data", response.data.data);
      })

      .catch((error) => {
        console.error("Error fetching order details:", error);
      });
  }, []);
  return (
    <div>
      {/* <NavBar dataSource={dataSource} /> */}
      <NavberUpdate dataSource={dataSource} />
      {/* <button
        className="button-LogOut"
        onClick={() => {
          localStorage.removeItem("sessionToken");
          window.location.href = "/";
        }}
      >
        logout
      </button> */}
      <Routes>
        <Route exact path="/" element={<Dashboard />} />
        <Route exact path="*" element={<NotFound />} />
        <Route path="/Profile" element={<Profile />} />
        <Route path="/AddItem" element={<AddItem />} />
        <Route path="/Slider" element={<Slider />} />
        <Route
          path="/Slider/UpdateSlider/:id"
          element={<UpdateSlider storeSlider={storeSlider} />}
        />
        <Route path="/Products" element={<Products />} />
        <Route
          path="/Products/UpdateProducts/:id"
          element={<UpdateProducts storeProducts={storeProducts} />}
        />
        <Route path="/OrderTable" element={<OrderTable />} />
        <Route path="/OrderTable/OrderDetails/:id" element={<OrderDetails />} />
        <Route path="/Profile/Network/:id" element={<Network />} />
      </Routes>
    </div>
  );
};

export default Home;
